import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleHttpErrorService } from './handle-http-error.service';
import { Observable } from 'rxjs';
import { Estudiante } from '../models/estudiante';
import { Horario } from '../models/horario';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleHttpErrorService) {
    this.baseUrl = "https://asistencias-apigateway-gwgjatgad7g4fmbg.eastus2-01.azurewebsites.net/matriculas/";
  }

  getHorarioEstudianteId(id:string): Observable<Horario> {
    const url = `${this.baseUrl}api/Horarios/ListarId/${id}`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.get<Horario>(url, httpOptions);
  } 

  getHorarios(): Observable<Horario[]> {
    const url = `${this.baseUrl}api/Horarios/Listar`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.get<Horario[]>(url, httpOptions);
  } 

  postHorarios(horario:any): Observable<any> {
    const url = `${this.baseUrl}api/Horarios/Guardar`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.post<any>(url,horario, httpOptions);
  } 
}
