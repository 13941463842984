import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Estudiante } from '../models/estudiante';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HandleHttpErrorService } from './handle-http-error.service';
import { Usuario } from '../models/usuario';
import { Users } from '../models/users';

@Injectable({
  providedIn: 'root'
})
export class MatriculasService {

  baseUrl: string;
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleHttpErrorService) {
    this.baseUrl = "https://asistencias-apigateway-gwgjatgad7g4fmbg.eastus2-01.azurewebsites.net/matriculas/";
  }

  postMatricula(matricula:any): Observable<any> {
    const url = `${this.baseUrl}api/Matriculas/Guardar`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.post<any>(url,matricula, httpOptions);
  } 

  getMatriculas(id:string): Observable<any[]> {
    const url = `${this.baseUrl}api/Matriculas/ListarPorEstudiante/${id}`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.get<any[]>(url, httpOptions);
  } 

  getMatriculasPorGrupos(id:any): Observable<any[]> {
    const url = `${this.baseUrl}api/Matriculas/ListarGrupoId/${id}`;
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      }),
    };
    return this.http.get<any[]>(url, httpOptions);
  } 
}
